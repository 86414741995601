var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.loadConfigsConceptos()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"Nombre","autocomplete":"off"},model:{value:(_vm.nombreConcepto),callback:function ($$v) {_vm.nombreConcepto=$$v},expression:"nombreConcepto"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"disabled":_vm.todosConceptos,"label":"Solo vigentes","dense":"","outlined":""},on:{"change":function($event){return _vm.switchFiltroSoloVigentes()}},model:{value:(_vm.soloConceptosVigentes),callback:function ($$v) {_vm.soloConceptosVigentes=$$v},expression:"soloConceptosVigentes"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"disabled":_vm.soloConceptosVigentes,"label":"Todos","dense":"","outlined":""},on:{"change":function($event){return _vm.switchFiltroTodosConceptos()}},model:{value:(_vm.todosConceptos),callback:function ($$v) {_vm.todosConceptos=$$v},expression:"todosConceptos"}})],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","disabled":!_vm.isFormValid,"type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.configsConceptos,"search":_vm.search,"loading":_vm.loading,"item-key":"liqDesConcId"},scopedSlots:_vm._u([{key:"item.esVigente",fn:function(ref){
var item = ref.item;
return [(item.esVigente)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3","align":"center"}},[(_vm.canConfigComisiones)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.goToConfigComisiones()}}},[_vm._v(" Comisiones ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"1","align":"end"}},[(_vm.canCreate)?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalEditConcepto()}}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditConcepto(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar concepto")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteConcepto(item.liqDesConc_id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar concepto")])]):_vm._e()]}}],null,true)})],1),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"45%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditConceptoDesregulacion',{attrs:{"conceptoDesregulacion":_vm.conceptoDesregulacion},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDeleteConcepto()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }