<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{
          verConcepto ? formSeeTitle : formEditTitle
        }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Concepto -->
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="conceptoNom"
                label="Nombre (*)"
                auto-grow
                rows="1"
                dense
                outlined
                :disabled="verConcepto"
                :filled="verConcepto"
                :readonly="verConcepto"
                :rules="
                  rules.required.concat([
                    rules.maxLength(conceptoNom, 100),
                    rules.requiredTrim(conceptoNom)
                  ])
                "
              ></v-textarea>
            </v-col>
            <!-- Proceso -->
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="procesoSelected"
                :items="procesos"
                item-text="value"
                item-value="id"
                label="Proceso"
                outlined
                clearable
                dense
                :disabled="verConcepto"
                :filled="verConcepto"
                :readonly="verConcepto"
              >
              </v-autocomplete>
            </v-col>
            <!-- Vigente -->
            <v-col cols="12" md="4" class="py-0">
              <v-switch
                class="mt-1"
                v-model="esVigente"
                label="Vigente"
                dense
                outlined
                :disabled="verConcepto"
                :filled="verConcepto"
                :readonly="verConcepto"
              ></v-switch>
            </v-col>
            <!-- Orden -->
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="orden"
                label="Orden"
                v-mask="'#####'"
                dense
                outlined
                :disabled="verConcepto"
                :filled="verConcepto"
                :readonly="verConcepto"
              ></v-text-field>
            </v-col>
            <!-- Signo -->
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="signo"
                :rules="[signo == -1 || signo == 1 || 'Formato incorrecto']"
                label="Signo (*)"
                hint="-1 (Resta); 1 (Suma)"
                dense
                outlined
                onkeypress="return (event.charCode == 45 || event.charCode == 49)"
                :disabled="verConcepto"
                :filled="verConcepto"
                :readonly="verConcepto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          v-if="!verConcepto"
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConceptoDesregulacion",
  directives: { mask },
  props: {
    conceptoDesregulacion: {
      Type: Object,
      required: false
    },
    verConcepto: {
      Type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    formEditTitle: enums.titles.EDIT_CONCEPTO_DESREGULACION,
    formSeeTitle: enums.titles.SEE_CONCEPTO_DESREGULACION,
    rules: rules,
    isFormValid: false,
    conceptoNom: null,
    procesoSelected: null,
    procesos: [],
    esVigente: true,
    orden: null,
    signo: null,
    loadingSaveBtn: false
  }),
  created() {
    this.setSelects();
  },
  mounted() {
    if (this.conceptoDesregulacion != null) this.setConceptoDesregulacion();
    else this.formEditTitle = "Nuevo concepto de desregulación";
  },
  methods: {
    ...mapActions({
      getProcesosLiquidacion: "convenios/getProcesosLiquidacion",
      saveConfiguracionConcepto: "convenios/saveConfiguracionConcepto",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const procesos = await this.getProcesosLiquidacion();
      this.procesos = procesos;
    },
    setConceptoDesregulacion() {
      this.conceptoNom = this.conceptoDesregulacion.liqDesConc_Nom;
      this.procesoSelected = this.conceptoDesregulacion.liqDelconcAut_id;
      this.esVigente = this.conceptoDesregulacion.esVigente;
      this.signo = this.conceptoDesregulacion.signo;
      this.orden = this.conceptoDesregulacion.orden;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        liqDesConcId: this.conceptoDesregulacion?.liqDesConc_id,
        nombre: this.conceptoNom,
        liqDesconcAutId: this.procesoSelected,
        esVigente: this.esVigente,
        signo: this.signo,
        orden: this.orden
      };
      const response = await this.saveConfiguracionConcepto(data);
      if (response.status === 200) {
        this.loadingSaveBtn = false;
        this.closeModal();
        this.setAlert({ type: "success", message: "Guardado con éxito." });
      } else this.loadingSaveBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56, 1);
}
</style>
